.jumbotron-container
  @extend .text-center
  @extend .mb-3
  background-color: $body-bg

  .app-jumbotron
    @extend .d-flex
    @extend .justify-content-center
    @extend .align-items-center
    background-image: url("../images/jeff-hardi-yRAK7Vcyl_Q-unsplash.jpg")
    background-size: 100% auto
    background-position: center
    background-repeat: no-repeat
    height: 500px
  h1
    color: white
    font-size: 6rem

.app-container
  @extend .container

  .header-image
    @extend .img-fluid

.matches-container
  @extend .row
  @extend .mt-5

  h2
    @extend .text-center
    color: white

  .fetch-matches, .show-matches
    @extend .col-lg-6

.app-about
  h1
    @extend .text-center
    color: white

.streamer-cell
  @extend .col-lg-4
  @extend .p-3

  .streamer-image
    @extend .text-center

  .streamer-url
    @extend .text-center
    @extend .mb-1
    @extend .mt-1

  .streamer-login
    @extend .text-center
    font-size: 2rem

  .streamer-views
    @extend .text-center
    font-size: 2rem
    color: white


.list-matches-container
  @extend .p-3
  background-color: white
  .match-list-item:nth-of-type(odd)
    background-color: #e0e0e0
  .match-list-item:nth-of-type(even)
    //background-color: #e0e0e0


.match-list-item
  @extend .mb-3
  @extend .p-2
  @extend .row

  .list-item-id
    @extend .d-flex
    @extend .justify-content-center
    @extend .align-items-center
    @extend .col-lg-2

  .match-side
    @extend .col-lg-4

    &.victory
      border-left: 2rem green solid

    &.defeat
      border-left: 2rem brown solid


  .hero-player-card
    display: inline-block
    border-bottom: 2px solid transparent
    &.tracked-player-card
      border-bottom: 5px solid orange

.mmr-table
  @extend .row
  @extend .offset-lg-4
  //@extend .d-flex
  //@extend .justify-content-center
  //@extend .align-items-center
  color: white
  .column
    @extend .col-lg-4
    @extend .mt-3

